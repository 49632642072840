type NumberStringType = {
  [key: number]: string
}

export const ExportStatus: NumberStringType = {
  0: '処理中',
  1: '保存可能',
  2: '取消済',
  3: '処理失敗',
  9: '削除済',
}