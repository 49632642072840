import React , {useEffect, useState} from 'react'
import Layout from 'components/Layout'
import { Box, Typography, Stack, IconButton, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Button, Snackbar, Alert, Link, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import FolderZipIcon from '@mui/icons-material/FolderZip'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { useNavigate, useParams } from 'react-router-dom'
import OperationProof from 'components/OperationProof'
import { useGetExportList, usePatchExport } from 'api/export'
import { Export } from 'api/export/responseType'
import { ExportStatus } from 'types/ExportObjects'
import ConfirmModal, { ConfirmModalOnCloseProps, ConfirmModalProps } from 'components/ConfirmModal'
import { getExportUrl } from 'lib/getImagePath'
import { useGetAngleNameSetting } from 'api/setting'
import { AngleName } from 'api/setting/responseType'
import { getAngleName } from 'types/PhotoObject'

type ExportListPageParams = {
  clinicId: string
}

const ExportList = () => {
  const urlParams = useParams<ExportListPageParams>()
  const clinicId = urlParams.clinicId ?? ''
  const [exports, setExports] = useState<Export[]>([])
  const { mutate: patchMutate } = usePatchExport()
  const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmModalProps | undefined>()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')
  const navigate = useNavigate()
  const {
    data: exportData, isError: isExportError, isPending: isExportPending
  } = useGetExportList()
  const {
    data: angleNameSettingData, isError: isAngleNameSettingError, isPending: isAngleNameSettingPending
  } = useGetAngleNameSetting()
  const [angleNameSetting, setAngleNameSetting] = useState<AngleName[]>([])

  useEffect(() => {
    if (isExportError || isAngleNameSettingError) {
      console.error(isExportError)
      console.error(isAngleNameSettingError)
      return
    }

    if(!isExportPending && exportData !== undefined){
      setExports(exportData.exports)
    }
    if(!isAngleNameSettingPending && angleNameSettingData !== undefined){
      setAngleNameSetting(angleNameSettingData.angleNames)
    }
  }, [
    exportData, isExportError, isExportPending,
    angleNameSettingData, isAngleNameSettingError, isAngleNameSettingPending
  ])

  if (isExportPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  const handleChangeStatus = async (id: number, status: number) => {
    const message = status === 2 ? 'エクスポートを停止します。よろしいですか？' : 'ファイルを削除します。よろしいですか？'
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: message,
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'ok') {
      patchMutate(
        { req: { status }, id },
        {
          onSuccess: (response) => {
            if (response.status === 'OK') {
              navigate(0)
            } else {
              setSnackBarOpen(true)
              setSnackBarMessage('エラー 422')
              setSnackBarFlag('error')
            } 
          },
          onError: (error) => {
            console.error(error)
            setSnackBarOpen(true)
            setSnackBarMessage('エラー 422')
            setSnackBarFlag('error')
          }
        }
      )
    }
    if (ret.modalStatus === 'cancel') {
      return
    }  
  }

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  const saveImage = (loadImg: HTMLImageElement, filename: string) => {
    const canvas = document.createElement("canvas")
    const context: CanvasRenderingContext2D | null = canvas.getContext("2d")
    // 画像サイズを設定し<canvas>要素に画像データを読み込む
    canvas.width = loadImg.width
    canvas.height = loadImg.height
    if (context) {
      context.drawImage(loadImg, 0, 0)
      const type = "image/jpeg"
      const uri = canvas.toDataURL(type, 0.85)
      canvas.toBlob((blob: any) => {
        if (blob) {
          const link = document.createElement("a")
          link.href = uri
          link.download = filename.split('/')[1]
          document.body.appendChild(link)
          link.click()
          link.remove()
          URL.revokeObjectURL(uri)
        }
      });
    }
  }

  const handleDownload = (filename: string, isImage: boolean) => {
      const url = getExportUrl(clinicId, filename)
      if (isImage) {
        const loadImg = new Image()
        loadImg.crossOrigin = "Anonymous"
        loadImg.addEventListener("load", () => saveImage(loadImg, filename), false)
        // 以下を実行すると、loadイベントが起こりsaveImageメソッドが実行される。
        loadImg.src = url
      } else {
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        document.body.appendChild(link)
        link.click()
        link.remove()
        URL.revokeObjectURL(url)
      }
  }
  
  const getSettingAngleName = (angle: number) => {
    const setting = angleNameSetting.find(item => item.angle === angle)
    const defaultName = getAngleName(angle)
    return setting ? setting.name : defaultName
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <Box sx={{ background: "white", borderRadius: "16px", pt: 3, px: 3, pb: 0.5, mt: 1, mb: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <DownloadIcon sx={{ color: theme.palette.text.primary }}/>
                </Box>
                <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>エクスポートファイル一覧</Typography>
              </Stack>
            </Stack>
            <Stack mt={2}>
              <Typography color={theme.palette.text.primary}>エクスポートファイルもデータ量に加算されます。ダウンロード後はゴミ箱ボタンで削除することをお勧めいたします。</Typography>
            </Stack>
            {
              isExportPending ?
              <Stack justifyContent="center" alignItems="center" width={1 / 1} mt={2} mb={4}>
                <CircularProgress />
              </Stack>
              :
              <Table sx={{ width: "100%",border: "1px solid #D9D9D9", tableLayout: "fixed", p: 0, my: 3 }}>
                <TableHead sx={{background: "#EEEEEE"}}>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={600} fontSize={14}>
                        出力対象
                      </Typography>
                    </TableCell>
                    <TableCell width={160}>
                      <Typography fontWeight={600} fontSize={14}>
                        ステータス
                      </Typography>
                    </TableCell>
                    <TableCell width={160}>
                      <Typography fontWeight={600} fontSize={14}>
                        ダウンロード期限
                      </Typography>
                    </TableCell>
                    <TableCell width={240}>
                      <Typography fontWeight={600} fontSize={14}>
                        アクション
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exports.map((item: Export, index) => {
                    const exportType = item.exportType
                    const status = item.status
                    const targetLink = exportType === 1 ?
                      `/${clinicId}/patient/${item.patientId}` :
                      [2, 3].includes(exportType) ?
                      `/${clinicId}/patient/upload/${item.patientId}/${item.examinationId}` : ''
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Stack direction='row' alignContent='center' gap={1}>
                            <Tooltip
                              title={exportType === 3 ? '編集済写真ダウンロード' : 'エクスポート'}
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, -14],
                                      },
                                    },
                                  ],
                                },
                              }}
                            >
                              {
                                exportType === 3 ?
                                <PhotoCameraIcon/> :
                                <FolderZipIcon/>
                              }
                            </Tooltip>
                            {
                              exportType === 0 ?
                              <Typography>
                                全体
                              </Typography> :
                              <Link href={targetLink} color={theme.palette.text.primary} underline="hover">
                                <Typography display="inline">{item.patientName}</Typography>
                                <Typography display="inline" fontSize={14}>({item.patientNo})</Typography>
                                {
                                  item.examinationDate &&
                                  <Typography display="inline"> {item.examinationDate}</Typography>
                                }
                                {
                                  item.angle &&
                                  <Typography display="inline"> {getSettingAngleName(item.angle)}</Typography>
                                }
                              </Link>
                            }
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ border: "1px solid #808080", borderRadius: "40px", py: "4px", px: 1, fontSize: "12px", textAlign: "center", width: 100}}>
                            {ExportStatus[status]}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {item.expiresAt ?? '-'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {
                            status === 0 && exportType !== 3 ?
                            <Button
                              sx={{ width: 160, textAlign: "center", fontSize: "14px", background: theme.palette.secondary.main, borderRadius: "40px", px: 4, fontWeight: "bold", "&:hover": { background: "#11867F", color: "white" } }}
                              onClick={() => handleChangeStatus(item.id, 2)}
                            >
                                キャンセル
                            </Button> :
                            status === 1 ?
                            <Button
                              sx={{ width: 160, textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 4, fontWeight: "bold", "&:hover": { background: "#11867F" } }}
                              onClick={() => handleDownload(item.filename, exportType === 3)}
                            >
                              ダウンロード
                            </Button> :
                            <Typography>-</Typography>
                          }
                          {
                            status === 1 ?
                            <IconButton
                              sx={{ p: 0, "&:hover": { backgroundColor: "transparent" }, border: "1px solid #A8A8A8", width: "40px", height: "40px", ml: 2 }}
                              onClick={() => handleChangeStatus(item.id, 9)}
                            >
                              <DeleteIcon/>
                            </IconButton> : <></>
                          }
                        </TableCell>
                      </TableRow>
                    )}
                  )}
                </TableBody>
              </Table>
            }
          </Box>
          {confirmModalConfig && <ConfirmModal {...confirmModalConfig} />}
          <Snackbar
              autoHideDuration={3000}
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={snackBarOpen}
              onClose={handleSnackClose}
          >
            <Alert
              severity={snackBarFlag === 'error' ? 'error' : 'success'}
              sx={{ width: '100%' }}
              variant="filled"
            >{snackBarMessage}</Alert>
          </Snackbar>
        </Layout>
      </ThemeProvider>
    </>
  )
}

export default ExportList

