import { getEditedPhoto } from "api/photo/request"

type SnackBarFunctions = {
  setSnackBarOpen: Function
  setSnackBarMessage: Function
  setSnackBarFlag: Function
}

export const handleEditedPhotoDownload = (patientId: number, tableName: 'photos'|'vagabonds', photoId: number, snackBarFunctions: SnackBarFunctions) => {
  const {setSnackBarOpen, setSnackBarMessage, setSnackBarFlag} = snackBarFunctions
  setSnackBarOpen(true)
  setSnackBarMessage('編集画像のエクスポートを開始します。進捗はエクスポート一覧メニューにてご確認ください。')
  setSnackBarFlag('')
  getEditedPhoto(patientId, tableName, photoId).catch(e => {
    console.error(e)
  })
}