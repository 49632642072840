import React, { useState } from 'react'
import { Typography, Box, Stack, Button, Tooltip, TooltipProps, tooltipClasses, Snackbar, Alert } from '@mui/material'
import theme from '../../style/theme'
import type { Photo } from 'api/photo/responseType'
import PhotoFrame from 'components/PhotoFrame'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { calculateThatTimeAge } from 'lib/patientService'
import { PatientPhotoItemProps } from 'types/PhotoObject'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import CarouselPhoto, { CarouselPhotoModalProps } from 'components/CarouselPhoto'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#242424',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    fontSize: theme.typography.pxToRem(12),
    border: '#242424',
  },
}))

const PatientPhotoStorage = (props : PatientPhotoItemProps) => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const { examinationData, patientData, examinationArray, examinationIndex, angleNameGetter } = props
  const [carouselPhotoModalConfig, setCarouselPhotoModalConfig] = useState<CarouselPhotoModalProps | undefined>()
  const navigate = useNavigate()
  const [ tooltipSettingOpen, setTooltipSettingOpen ] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')

  const handleViewerOpen = async (angleindex: number) => {
    const photoMatrix: (Photo | undefined)[][] = examinationArray.filter((exam) => {
      const vaganonds = exam.vagabond ?? []
      return vaganonds.length > 0
    }).map(exam => exam.vagabond ?? [])
    await new Promise<CarouselPhotoModalProps>((resolve) => {
      setCarouselPhotoModalConfig({
        onClose: resolve,
        patientData: patientData,
        examinations: examinationArray,
        carousel: photoMatrix,
        initialAngleIndex: angleindex,
        initialDateIndex: examinationIndex,
        angleNameGetter: angleNameGetter,
        onlyHorizon: true,
        setSnackBarOpen,
        setSnackBarMessage,
        setSnackBarFlag
      })
    })
    setCarouselPhotoModalConfig(undefined)
  }

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  const TooltipSettingContent = () => {
    const style = {
      p: 0,
      '&:hover': { color: theme.palette.primary.main, backgroundColor: 'transparent' }
    }
    return patientData ?
    (
      <>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
          onMouseDown={() => navigate(`/${clinicId}/patient/swap/` + patientData?.id +"/"+ examinationData.id)}
        >
          <Typography color='white' sx={style}>
            移動
          </Typography>
        </Button>
      </>
    ) : (<></>)
  }

  const handleButtonSettingClicked = () => {
    setTooltipSettingOpen(!tooltipSettingOpen)
  }

  const handleTooltipSettingClose = () => {
    setTooltipSettingOpen(false)
  }

  const itemLength = examinationData.vagabond?.length ?? 0
  const viewWidth = `calc(168px * ${itemLength})`

  return (examinationData.vagabond?.length ?? 0) > 0 && patientData ? (
    <>
      <Stack sx={{ border: '2px solid #F4F4F4', alignItems: "center", mb: 2 }} direction = "row">
        <Stack sx={{ width: '150px', display: "flex", alignItems: "center", backgroundColor: "#F4F4F4", height: "134px", justifyContent: "center" }} direction = "column">
          <Typography fontWeight="bold" color="#364F4E">
            {examinationData.examinationDate}
          </Typography>
          <Typography fontSize={12} color="#364F4E">
            {calculateThatTimeAge(patientData.birthday, examinationData.examinationDate)}
          </Typography>
          <Stack sx={{ columnGap: 1, mt: 1 }} direction="row">     
            <HtmlTooltip
              title={
                <TooltipSettingContent />
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={tooltipSettingOpen}
              onBlur={handleTooltipSettingClose}
            >
              <Button
                variant="outlined"
                sx={{ py: 0 ,background: theme.palette.primary.main , color: "white", borderRadius: "30px", width: "24px", fontSize: "12px", "&:hover": { backgroundColor: "white", color: theme.palette.primary.main, borderColor: theme.palette.primary.main } }}
                onClick={handleButtonSettingClicked}>
                設定
              </Button>
            </HtmlTooltip>
          </Stack>
        </Stack>
        <Stack sx={{ width: 'calc(100% - 120px)', scrollbarWidth: "none", msOverflowStyle :"none" }} overflow="scroll" whiteSpace="nowrap">
          <Scrollbars style={{ width: '100%', height: '134px'}}>
            <Stack alignItems="center" justifyContent="flex-start" gap={0} direction="row" width={viewWidth}>
              {
                examinationData.vagabond &&
                examinationData.vagabond.map((item, index) => {
                  return (
                    <Button 
                      onClick={() => handleViewerOpen(index)}
                      key={index}
                    >
                      <Box sx={{ px: 1 }}>
                        <PhotoFrame
                          image={item}
                        />
                      </Box>
                    </Button>
                  )
                })
              }
            </Stack>
          </Scrollbars>
        </Stack>
      </Stack>
      {carouselPhotoModalConfig && <CarouselPhoto {...carouselPhotoModalConfig} />}
      <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}
          open={snackBarOpen}
          onClose={handleSnackClose}
      >
        <Alert
          severity={snackBarFlag === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
          variant="filled"
        >{snackBarMessage}</Alert>
      </Snackbar>
    </>
  ) : (
    <></>
  )
}
export default PatientPhotoStorage
